.tabs {
    color: #999;
    display: flex;

    > div {
        margin-right: 1rem;

        &.selected{
            border-bottom: 4px solid black;
            cursor: pointer;
            color: #464646;
        }
    }
    
    .tab-links {
        border-bottom: 1px solid black;
        display: flex;

        >span {
            font-size: 0.85rem;
            display: block;
            margin-right: 1.5rem;
            padding: 0.75rem 0;

            &.selected, &:hover{
                border-bottom: 4px solid black;
                cursor: pointer;
            }
        }
    }
}