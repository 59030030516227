.panel-chart {
    padding: 0 0 2rem 0;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    > p {
        margin-bottom: 2rem;
        line-height: 1.5;

        span {
            font-weight: 400;
        }
    }
    .chart-container {
        align-self: center;
        position: relative;
    }

    .left-panel-custom-tooltip {
        background: white;
        box-shadow: $shadow;
        padding: 0.65rem 1rem;
        border-radius: 0.5rem;
        
        p {
            margin: 0;
            text-transform: uppercase;
            font-weight: 500;

            span {
                font-size: 1.15rem;
                font-weight: 600;
                display: block;
            }
        }
    }

    .share {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        p {
            margin: 0;
            line-height: 1;
            font-size: 2rem;
            text-align: center;
            font-weight: bold;
        }
    }
}
