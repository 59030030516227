
.tbl-view {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  font-size: 0.85rem;
  padding: 2rem;
  background-color: $light-grey;
  overflow-x: auto;

  &-top {
    position: sticky;
    top: 0;
    margin-bottom: 2rem;
    z-index: 1000;
    box-shadow: $shadow;
  }

  &-bottom {
    overflow-y: scroll;
    position: relative;
    box-shadow: $shadow;
  }

  &-scroll-tbl {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-tbl-block {
    background: white;
    padding: 0 1rem 1rem 1.5rem;
    border-radius: 0.75rem;
    max-height: 100%;
  }

  .tbl-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
    background: white;

    > span {
      padding: 1.75rem 1rem 1rem 1rem;
      font-weight: 500;
      font-weight: 600;
      flex: 1;
    }
  }

  .threshold-cell {
    font-weight: bold;
    color: #111111;
  }

  .tbl-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #eaeaea;
    background: white;
    color: #666;

    > span {
      padding: 0.75rem 1rem;
      flex: 1;
      display: flex;
      align-items: center;
    }

    &.row-hover {
      span {
        &:first-child {
          text-decoration: underline;
          font-weight: 400;
        }
      }

      &:hover {
        background: $cream;
        cursor: pointer;
      }
    }

    &.selected {
      position: relative;
      border-left: 2px solid $ls-orange;
      background: $cream;
      font-weight: 500;
    }
  }
}

.tbl-view-breadcrumbs {
  padding: 1rem;
  margin-bottom: 1.5rem;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  color: #111;

  svg {
    color: inherit;
    height: 1rem;
  }

  &:hover {
    color: $ls-orange;
    cursor: pointer;
  }

  &.nohover {
    color: #111;
    cursor: default;
    padding-left: 2rem;
  }
}
