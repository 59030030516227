.landing {

    .landing-nav {
        padding: 0 2rem;
        height: $nav-top-height;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0;
        background: white;

        a {
            display: inline-flex;
            align-items: center;
            height: 2.25rem;
            background: #111;
            color: white;
            padding: 0 1.25rem;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 0.75rem;
            font-weight: 400;
            border-radius: 4px;
        }

    }

    section {
        background: $light-beige;

        &:nth-of-type(odd) {
            background: white;

            .copy {
                flex-direction: row-reverse;

                .text {
                    padding-left: 0;
                    padding-right: 4rem;
                }
            }
        }

        .copy {
            display: flex;

            .text {
                flex: 1;
                padding-left: 4rem;
                padding-right: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &-inner {
                    max-width: 50%;
                    padding: 3rem 0;
                }

                h1 {
                    font-size: 3rem;

                    var {
                        font-style: normal;
                        color: $ls-orange;
                    }
                }

                h2 {
                    font-size: 2rem;
                }

                p {
                    line-height: 1.5;
                    font-size: 1.125rem;
                    color: $ls-midgrey;
                }

                ul, li {
                    line-height: 1.5;
                    font-size: 1.125rem;
                    color: $ls-midgrey;
                }

                a {
                    margin-top: 1rem;
                    display: inline-flex;
                    align-items: center;
                    height: 2.25rem;
                    background: #111;
                    color: white;
                    padding: 0 1.25rem;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-size: 0.75rem;
                    font-weight: 400;
                    border-radius: 4px;
                }
            }

            .pic {
                flex: 0.8;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        footer {
            background: linear-gradient(#3F3F3F, #111111);
            padding: 8rem 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            p {
                color: white;
                font-size: 0.75rem;
            }
        }
    }
}
