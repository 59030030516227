.summary-conatiner {
    .stat-card {
        box-sizing: border-box;
        background: white;
        border-radius: 0.75rem;
        box-shadow: 0px 2px 11px -4px rgba(0,0,0,0.15);
        border: 1px solid #eaeaea;
    }
}

.summary-welcome {
    background: black;
    color: white;
    padding: 0 $SingleColPagePadding;
    height: 460px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-text {
        margin-top: -6rem;
    }

    h1 {
        margin: 0;
        font-size: 2.75rem;
    }

    p {
        font-size: 1.25rem;
    }
}

.summary-stats {
    padding: 2rem $SingleColPagePadding;

    .summary-stats-row {
        width: 100%;
        padding:  0 0 2rem 0;
        display: grid;
        grid-column-gap: 2rem;
        grid-template-columns: auto auto auto auto;

        &.top-row {
            margin-top: -8rem;
        }

        .stat-card {

            &.icon-card {
                width: span 1;
            }
            
            &.chart-card {
                grid-column: span 3;
            }

            &.tbl-card {
                grid-column: span 2;
            }

            &.alerts-card {
                grid-column: span 2;
            }
        }
    }

    .icon-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        &-info {
            svg {
                position: absolute;
                right: 1rem;
                top: 1rem;
                stroke: #EFAC41;
                fill: #F7D59A;
            }
        }

        &-heading {
            padding: 2rem 0 1.5rem;
            font-weight: bold;
            font-size: 1.15rem;
        }

        &-pie{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .pie-numbers {
                position: absolute;
                text-align: center;

                .main {
                    font-size: 2.5rem;
                    font-weight: bold;
                    margin: 0;
                }

                .trend {
                    display: block;
                    margin-left: -0.5rem;
                    font-weight: 600;
                    margin-top: 0.25rem;

                    svg {
                        height: 0.85rem;
                    }

                    &.positive {
                        color: green;

                        svg {
                            stroke: green;
                        }
                    }

                    &.negative {
                        color: red;

                        svg {
                            stroke: red;
                        }
                    }
                }
            }
        }

        &-lbl {
            margin-top: 1.25rem;
            font-size: 0.75rem;
            font-weight: 600;
            color: #A0A0A0;
        }
    }

    .chart-card {

        .card-title {
            padding:1rem 1rem 2.5rem 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .heading {
                font-weight: 600;
                font-size: 1.25rem;
            }

            button {
                background: #111;
                color: white;
                border-radius: 0.25rem;
                height: 2.75rem;
                padding: 0 1.25rem;
                box-sizing: border-box;
                font-size: 1rem;
                border: none;
            }
        }

        .chart-block {
            height: 240px;
            width: 100%;
        }
    }

    .tbl-card {

        &-title {
            padding:0 2rem;
            line-height: 4rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eaeaea;
            background: $cream;
            border-radius: 0.5rem 0.5rem 0 0;

            .heading {
                font-weight: 400;
                font-size: 1.25rem;

                .tabs {
                    display: flex;

                    .tab {
                        margin-right: 2rem;
                        color: $ls-midgrey;
                        border-bottom: 4px solid transparent;

                        &.selected {
                            color: #111;
                            border-bottom: 4px solid #111;
                        }
                    }
                }
            }

            a {
                font-size: 0.85rem;
                color: #7d7d7d;
            }
        }

        .tbl-block {
            padding:1.5rem 2rem 1.5rem 2rem;
            display: flex;
            flex-direction: column;

            .tbl-row {
                display: flex;
                flex: 1;
                justify-content: space-between;
                padding: 0.75rem 0;

                &.tbl-header {
                    color: #7D7D7D;
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;

                }

                > div {
                    flex: 2;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }

                a {
                    color: #111;

                    &:hover {
                        color: $ls-orange;
                    }
                }
            }
        }
    }

    .alerts-card {
        .alert {
            padding: 0.5rem 2rem 0.5rem 2rem;
            box-sizing: border-box;
            border-bottom: 1px solid #eaeaea;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-copy {
                display: flex;
                align-items: center;

                .icon {
                    padding: 0.5rem;
                    background: $cream;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        stroke: $ls-orange;
                        height: 1.5rem;
                        width: auto;
                    }

                    .alert-i {
                        stroke: red;
                    }
                }

                p {
                    padding-left: 2rem;
                    line-height: 1.5;
                }

                .alert-copy-txt {

                    >span {
                        position: relative;
                    }

                    svg {
                        top: 2px;
                        height: 1rem;
                        position: absolute;
                        border: 1px solid #ccc;
                        padding: 0 0.25rem;
                        border-radius: 0.5rem;
                        margin-left: 0.5rem;
                        background: #fafafa;
                    }
                }
            }

            svg {
                stroke: $ls-midgrey;
                height: 1.25rem;
                width: auto;
            }

            &:last-child {
                border: none;
            }
        }
    }
}
