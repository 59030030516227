.map {
  height: 100%;

  .map-loading {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: rgba(17, 17, 17, 0.6);

    .loading-dialog {
      position: absolute;
      width: 350px;
      height: 200px;
      left: 50%;
      top: 50%;
      background: #ffffff;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.05);
      border-radius: 20px;

      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      p {
        font-family: Nunito Sans;
        font-size: 16px;
      }
    }
  }

  .map-legend {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;

    .heat-select {
      pointer-events: all;
    }

    .popup-options {
      position: absolute;
      background: white;
      font-size: 0.75rem;
      box-shadow: $shadow;
      border-radius: 0.5rem;
      right: 190px;
      width: 200px;

      &.show {
        display: block;
      }

      &.hide {
        display: none;
      }

      button {
        display: block;
        border: none;
        width: 100%;
        text-align: left;
        background: none;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        pointer-events: all;

        &.selected {
          background: $cream;
        }

        &.active,
        &:hover {
          background: $cream;
          cursor: pointer;
          border-radius: 0.5rem 0.5rem 0 0;
        }

        &:last-child {
          border-radius: 0 0 0.5rem 0.5rem;
        }
      }
    }

    .gradient {
      border-radius: 0.5rem;
      margin: 1rem 0 1rem 0;
      background: white;
      width: 85px;
      font-size: 0.75rem;
      padding: 0.5rem 1rem;
      box-shadow: $shadow;
      display: flex;
      justify-content: flex-end;

      .grad-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 0.5rem 2rem 0;
      }

      .grad-bar {
        padding: 2rem 0.5rem 2rem 0;

        span {
          display: block;
          height: 4.5rem;
          width: 1rem;

          &:nth-of-type(1) {
            border-radius: 0.5rem 0.5rem 0 0;
            background: #171108;
          }

          &:nth-of-type(2) {
            background: #896833;
          }

          &:nth-of-type(3) {
            background: #cf9c4e;
          }

          &:nth-of-type(4) {
            background: #ebbe75;
          }

          &:nth-of-type(5) {
            background: #f5dfb9;
            border-radius: 0 0 0.5rem 0.5rem;
          }
        }
      }
    }
  }

  .map_popup {
    padding: 0.5rem;

    &-block {
      padding-bottom: 1rem;

      div {
        color: #999;
        font-size: 0.75rem;

        &:last-child {
          font-size: 0.75rem;
          color: #111;
          font-weight: bold;
        }
      }
    }
  }
}

.map-popup {
  width: 250px;
  transform: translate(-50%, -100%);
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  border-radius: 12px;

  &:after {
    content: '';
    position: absolute;
    background: white;
    top: 100%;
    left: 50%;
    width: 16px;
    height: 16px;
    padding: 1px;
    margin: -10px -7px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.popup-inner-content {
  padding-bottom: 24px;

  .pop-top {
    letter-spacing: 1px;
    background: #f5f5f5;
    padding: 1.3rem 1.5rem;
    border-radius: 12px 12px 0 0;

    .dismiss {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }

    .type {
      font-size: 0.65rem;
      text-transform: uppercase;
      color: #6c6c6c;
      margin-bottom: 0.25rem;
    }

    .location {
      font-size: 1.25rem;
      font-weight: 500;
    }
  }

  .pop-bottom {
    padding: 1rem 1.5rem;

    .lbl {
      font-size: 0.65rem;
      text-transform: uppercase;
      color: #6c6c6c;
      padding-top: 0.5rem;
      padding-bottom: 0.25rem;
      letter-spacing: 1px;
    }

    .stats {
      color: #6c6c6c;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.75rem;

      .numbers {
        font-size: 0.75rem;
        letter-spacing: 1px;

        span {
          color: #111;
        }
      }
    }
  }

  .map-button-link {
    color: #4184ef;
    background-color: #f5f5f5;

    svg {
      width: 18px;
      margin-right: 8px;
    }
  }

  .map-button-link:hover {
    background-color: rgba(65, 132, 239, 0.04);
  }

  .map-button-link:first-child {
    margin-bottom: 12px;
  }
}


#mapid {
  position: relative;
}
