.reports {

    section {
        padding: 6rem $SingleColPagePadding;
        background: #F5F5F5;

        &.cream-bg {
            background: $cream;
        }

        h1 {
            display: flex;
            align-items: center;
            font-size: 2.5rem;
            font-weight: 600;
            width: 100%;
            border-bottom: 1px solid #111;
            padding-bottom: 1rem;

            span {
                display: inline-block;
                margin-right: 1rem;
            }
        }

        .card-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 2rem;
            padding-top: 2rem;
        }

        .report-insight-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 4rem;
            margin: 3rem 0 0 0;
        }

        .report-chartcard-block {
            display: flex;

            .copy-panel {
                width: 40%;
                padding: 0 2rem;
            }

            .chartcard-panel {
                width: 60%;
                padding: 0 2rem;

                .card-grid {
                    padding: 2rem;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 2rem;
                    grid-row-gap: 2rem;
                }

                .report-chart {
                    box-shadow: $shadow;
                    padding: 2rem 3rem 2rem 0;
                    border-radius: 0.75rem;
                    background: white;
                    margin-top: 2rem;

                    .heading {
                        padding: 0 0 2rem 3rem;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .graph-legend {
                            display: flex;
                            align-items: center;
                            
                            span {
                                display: block;
                                margin-right: 1rem;
                                font-size: 0.75rem;
                
                                svg {
                                    height: 0.75rem;
                                    stroke: 0;
                                }
                
                                &:nth-of-type(1){
                                    svg {
                                        fill: #3A7FA0;
                                        stroke: white;
                                    }
                                }
                
                                &:nth-of-type(2){
                                    svg {
                                        fill: #73B4D7;
                                        stroke: white;
                                    }
                                }
                
                                &:nth-of-type(3){
                                    svg {
                                        fill: #8DCDE2;
                                        stroke: white;
                                    }
                                }
                            }
                        }
                    }

                    .chart-block {
                        height: 300px;
                    }
                }
            }
        }
    }

    .section-hero {
        background: black;
        color: white;
        padding: 6rem $SingleColPagePadding 0 $SingleColPagePadding;

        .intro {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .report-intro {
                flex: 1;
            }

            .report-img {
                flex: 1;
                text-align: right;
                padding-right: 4rem;
            }

            .report-intro-actions {
                font-size: 0.85rem;

                svg {
                    height: 1rem;
                }

                span {
                    display: inline-block;
                    margin-right: 2rem;
                }
            }

            .report-intro-copy {
                padding-bottom: 6rem;

                h1 {
                    font-size: 3rem;
                }

                span {
                    font-size: 0.75rem;
                }
            }
        }

        .tabs {
            display: flex;

            .tab {
                color: $ls-midgrey;
                border-bottom: 4px solid black;
                margin-right: 6rem;
                height: 2.5rem;

                &.selected {
                    color: white;
                    border-bottom: 4px solid white;
                }
            }
        }
    }
}

.report-stat-card {
    border-radius: 0.75rem;
    box-shadow: $shadow;
    padding: 1.5rem;
    background: white;

    .report-stat-card-head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            text-transform: uppercase;
            color: $ls-midgrey;
            font-size: 0.75rem;
            letter-spacing: 1px;
            font-weight: 400;
        }

        svg {
            height: 1rem;
            color: #999;
        }
    }

    .report-stat-card-main {
        font-size: 2.5rem;
        font-weight: 500;
        padding: 1rem 0;
        font-weight: 800;

        &.negative{
            color: #E03131;
        }

        &.positive{
            color: #2B8A3E;
        }
    }

    .report-stat-card-trend {
        font-size: 0.85rem;
        display: flex;
        align-items: center;
        font-weight: 400;

        &.negative{
            color: #E03131;
        }

        svg {
            height: 1rem;
            margin-right: 0.25rem;

            &.positive {
                color: green;
            }
            &.negative {
                color: red;
            }
        }
    }
}

.report-insight-box {
    padding: 2rem 0;
    box-sizing: border-box;

    .tag {
        font-size: 0.75rem;
    }

    .heading {
        color: $ls-orange;
        font-size: 2.15rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        padding: 0.25rem 0;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            
            &:last-child {
                text-align: center;
                background: rgba(239,172,65,0.2);
                border-radius: 50%;
                padding: 0.5rem;
                width: 1.75rem;
                height: 1.75rem;
                margin-left: 0.5rem;
                svg {
                    height: 2rem;
                }
            }
        }
    }

    .insight-body {
        p {
            line-height: 1.5;
            
            &:first-child {
                font-weight: 600;
            }

            &.larger_txt {
                font-size: 1.25rem;
            }
        }
    }

    .view-btn {
        display: inline-block;
        padding: 0.5rem 1rem;
        color: $ls-midgrey;
        border: 1px solid $ls-midgrey;
        border-radius: 0.5rem;
        margin-top: 0.5rem;
        font-weight: 400;
    }
}
