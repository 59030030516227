.bcrumbs {
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 1px;
    display: flex;

    span {
        display: flex;
        align-items: center;
        white-space: nowrap;

        svg {
            width: 1rem;
        }
    }
}