.navbar {
    background: black;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1000;

    button {
        background: none;
        border-radius: 0.25rem;
        border: 1px solid black;
        padding: 0.25rem 0.65rem;
    }

    .nav-top {
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $nav-top-height;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pages {
            display: flex;
            align-items: center;

            .logo {
                display: block;
                margin: 0 2rem 0 1rem;

                img {
                    height: 1.75rem;
                    width: auto;
                }
            }

            a {
                color: #A0A0A0;
                text-decoration: none;
                margin-right: 3.25rem;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;

                &.selected, &:hover {
                    color: #fff;
                }
            }
        }

        .settings {
            display: flex;
            height: 1rem;
            align-items: center;

            span {
                margin-left: 2rem;
                line-height: 1;
                display: block;
                position: relative;
                padding-left: 0.75rem;
                width: 1rem;

                svg {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -0.5rem;
                    height: 1.25rem;
                    width: auto;
                }
            }
        }
    }

}


.nav-bottom {
    padding: 8px 0;
    background: white;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .collection-selector {
        min-width: 80%;
    }

    .separator {
        border: 1px solid #cccccc;
        height: 100%;
        margin-right: 30px;
    }

    .views {
        display: flex;
        align-items: center;
    }
    .end-nav {
        padding-right: 30px;
    }

    .action-btns {
        display: flex;

        button, a {
            height: 2.25rem;
            font-size: 0.85rem;
            display: flex;
            align-items: center;
            margin-left: 1rem;
            padding: 0 1rem;
            border-radius: 0.5rem;
            font-family: 'Nunito Sans', sans-serif;

            &.btn-filters {
                background: white;
                color: #7d7d7d;
                border: 1px solid #7D7D7D;
            }

            &.btn-report {
                background: $accent;
                color: white;
                border: 1px solid $accent;
                text-decoration: none;
            }

            svg {
                height: 1.125rem;
                width: auto;
                margin-right: 0.5rem;
            }

            &:hover {
                background: $ls-orange;
                border: 1px solid $ls-orange;
                cursor: pointer;
                color: white;

                svg {
                    stroke: white;
                }
            }
        }
    }
}
