$nav-top-height: 4.5rem;
$nav-bottom-height: 3.75rem;
$left-panel-width: 328px;

$SingleColPagePadding: 10rem;

$light-grey: #f5f5f5;
$light-beige: #fdf7ec;
$ls-orange: #efac41;
$cream: #fdf7ec;
$ls-midgrey: #6c6c6c;
$accent: #4184ef;

$shadow: 0px 25px 35px -10px rgba(102, 73, 28, 0.07);
