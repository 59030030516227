.insight-view {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow-y: scroll;
}

.insigt-block {
    padding: 3rem 0rem;

    .insight-block-header {
        display: flex;
        justify-content: space-between;

        padding-left: 2rem;

        .tabs {
            display: flex;
    
            .tab {
                border:none;
                margin: 0 2rem 1.5rem 0;
                padding-bottom: 0.25rem;
                font-weight: 800;
                font-size: 24px;
                line-height: 32px;
                color: #999;
                cursor: pointer;
                background-color: #f5f5f5;

                &.selected {
                    border-bottom: 4px solid #111;
                    color: #111;
                }
            }
        }

        .insight-filter {
            color: $ls-midgrey;

            span {
                display: inline-block;
                font-size: 0.75rem;

                &:first-child {
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }

                &:last-child {
                    margin-left: 0.5rem;
                    border: 1px solid $ls-midgrey;
                    border-radius: 0.5rem;
                    background: white;
                    display: inline-flex;
                    align-items: center;
                    padding: 0.5rem 0.15rem 0.5rem 0.65rem;
                    font-size: 0.85rem;
                    color: #111;

                    svg {
                        height: 1rem;
                    }
                }
            }
        }
    }
}

.insight-stats {
    display: flex;
    justify-content: left;
    padding-left: 2rem;

    .stat-box {
        width: 20%;
        margin-right: 31px;
    }
}

.color-bubble {
    display: block;
    margin-right: 1rem;
    font-size: 0.75rem;
    color: $ls-midgrey;

    svg {
        height: 0.65rem;
        width: auto;
        margin-right: 0.25rem;
        stroke: 0;
    }

}

.volume-chart {
    padding: 3rem 4rem 0 1rem;
    background: white;
    margin: 2rem;
    border-radius: 0.75rem;
    box-shadow: $shadow;


    .heading {
        font-weight: 700;
        font-size: 22px;
        color: #111;
        padding-left: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .graph-legend {
            display: flex;
            align-items: center;

            .line-option {
                height: 40px;
                border-radius: 20px;
                justify-content: space-between;
            }
        }
    }

    .you-label {
        align-items: flex-end;
        flex-direction: row-reverse;
    }

    .chart-block {
        padding: 20px 0 45px 0;
        width: 100%;
    }
}

.insight-block {
    display: flex;

    .insight-box {
        margin-top: 40px;
        margin-left: 10rem;
        box-sizing: border-box;

        .tag {
            color: $ls-midgrey;
            display: inline-block;
            padding: 0 3rem 0 2rem;
            height: 2.8rem;
            line-height: 2.8rem;
            font-size: 0.75rem;
            background: white;
            border-radius: 0 1.4rem 1.4rem 0;
        }

        .heading {
            font-size: 2.25rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            padding: 0.25rem 2rem;
            margin-top: 34px;

            span {
                
                &:last-child {
                    text-align: center;
                    background: $cream;
                    border-radius: 50%;
                    padding: 0.5rem;
                    width: 1.75rem;
                    height: 1.75rem;
                    margin-left: 0.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        height: 2rem;
                    }
                }
            }
        }

        .insight-body {
            padding: 0 2rem;

            p {
                line-height: 1.5;
                
                &:first-child {
                    font-weight: bold;
                }
            }
        }
    }
}

