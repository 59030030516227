/* Fonts are kept in src folder due to: https://github.com/facebook/create-react-app/issues/9870 */

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 400;
  src: url('src/fonts/NunitoSans-Regular.woff2') format('woff2'),
    url('src/fonts/NunitoSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 600;
  src: url('src/fonts/NunitoSans-SemiBold.woff2') format('woff2'),
    url('src/fonts/NunitoSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 700;
  src: url('src/fonts/NunitoSans-Bold.woff2') format('woff2'),
    url('src/fonts/NunitoSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Nunito Sans';
  font-weight: 800;
  src: url('src/fonts/NunitoSans-ExtraBold.woff2') format('woff2'),
    url('src/fonts/NunitoSans-ExtraBold.woff') format('woff');
}
