.showme {
    opacity: 1;
    z-index: 100;
}

.hideme {
    opacity: 0;
    z-index: -1;
}

.container {
    display: flex;
    flex-direction: column;

    height: 100vh;

    .right-panel {
        height: 100%;
        position: relative;
        overflow: hidden;
        background-color: #f5f5f5;
    }
}

.footer {
    background: linear-gradient(#3F3F3F, #111111);
    padding: 8rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 0.75rem;
    line-height: 1.5;
}

.map-popup {
    p {
        margin-bottom: 0 !important;
    }
}

.trend-pill {
    display: inline;
    padding: 4px;
    border-radius: 0.5rem;
    background: $cream;
    color: #A17A3C;
    font-weight: bold;
    font-size: 0.75rem;
    margin: 0 0.75rem;
    max-height: 24px;

    svg {
        height: 1rem;
        color: inherit;
    }
}

.stat-box {
    background: white;
    border: 1px solid #eaeaea;
    border-radius: 0.75rem;
    padding: 1.25rem 2rem;
    box-sizing: border-box;
    box-shadow: $shadow;

    .heading {
        color: #7D7D7D;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.15rem;
    }

    .stat-number {
        font-size: 2.75rem;
        font-weight: 600;
        color: #111;
        margin: 0.85rem 0;
    }

    .stat-footer {
        font-size: 0.85rem;
    }
}

.toollTip {
    position: relative;

    svg {
        height: 1rem;
    }

    > div {
        position: absolute;
        background: white;
        border: 1px solid $ls-midgrey;
        color: #111;
        border-radius: 0.5rem;
        box-shadow: $shadow;
        padding: 0.5rem 0.75rem;
        bottom: 2rem;
        left: -50%;
        max-width: 300px;
        min-width: 120px;
        z-index: 1000;
        text-transform: initial;
        font-size: 0.85rem;
        display: none;

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 0.75rem solid transparent;
            border-right: 0.75rem solid transparent;
            border-top: 0.75rem solid $ls-midgrey;
            bottom: -0.75rem;
        }
    }

    &:hover {
        cursor: pointer;
        > div {
            display: block;
        }
    }
}
