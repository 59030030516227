$panel-offset: 1.5rem;

.floating-panel {
    background: white;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    &-head {
        position: sticky;
        top: 0;
        padding: 1.5rem;
    }

    &-body {
        padding: 0 1.5rem;
        font-size: 0.85rem;
    }

    .tab-overview {
        font-size: 1.5rem;

        .tag {
            color: $ls-midgrey;
            font-size: 0.65rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 0.25rem;
        }

        p {
            font-size: 0.85rem;
            line-height: 1.4;
        }
    }

    .panel-inputs {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .inp {

            .lbl {
                font-size: 0.75rem;
                margin-bottom: 0.75rem;
                letter-spacing: 1px;
            }

            .panel-legend {
                .active {
                    background-color: $light-beige;
                }

                span {
                    display: block;
                    padding: 0 10px 0 10px;
                    font-size: 0.75rem;
                    margin-bottom: 0.5rem;
                    text-transform: uppercase;
                    border-radius: 5px;

                    svg {
                        height: 0.5rem;
                        width: auto;
                        margin-right: 0.5rem;
                    }
                }
            }

            input[type="text"] {
                width: 60px;
                height: 2.5rem;
                box-sizing: border-box;
                padding: 0.5rem;
                border-radius: 0.5rem;
                border: 1px solid #ccc;
                font-size: 1rem;
                color: #111;
                position: relative;
            }

            span {
                color: #111;
                font-size: 1.25rem;
            }

            .bar {
                width: 60px;
                height: 50px;
                box-sizing: border-box;
                padding: 0.5rem 0;
                display: flex;
                align-items: flex-end;

                .blk {
                    height: 6px;
                    border-radius: 0.5rem;
                    width: 100%;
                    background: $ls-orange;
                }
            }
        }
    }
}
